// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-android-tsx": () => import("./../../../src/pages/android.tsx" /* webpackChunkName: "component---src-pages-android-tsx" */),
  "component---src-pages-apartados-tsx": () => import("./../../../src/pages/apartados.tsx" /* webpackChunkName: "component---src-pages-apartados-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-aviso-modificacion-tsx": () => import("./../../../src/pages/aviso-modificacion.tsx" /* webpackChunkName: "component---src-pages-aviso-modificacion-tsx" */),
  "component---src-pages-aviso-privacidad-tsx": () => import("./../../../src/pages/aviso-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-tsx" */),
  "component---src-pages-buro-tsx": () => import("./../../../src/pages/buro.tsx" /* webpackChunkName: "component---src-pages-buro-tsx" */),
  "component---src-pages-comisiones-tsx": () => import("./../../../src/pages/comisiones.tsx" /* webpackChunkName: "component---src-pages-comisiones-tsx" */),
  "component---src-pages-contrato-adhesion-tsx": () => import("./../../../src/pages/contrato-adhesion.tsx" /* webpackChunkName: "component---src-pages-contrato-adhesion-tsx" */),
  "component---src-pages-controversias-y-aclaraciones-tsx": () => import("./../../../src/pages/controversias-y-aclaraciones.tsx" /* webpackChunkName: "component---src-pages-controversias-y-aclaraciones-tsx" */),
  "component---src-pages-educacion-financiera-glosario-tsx": () => import("./../../../src/pages/educacion-financiera/glosario.tsx" /* webpackChunkName: "component---src-pages-educacion-financiera-glosario-tsx" */),
  "component---src-pages-educacion-financiera-proteccion-identidad-y-datos-tsx": () => import("./../../../src/pages/educacion-financiera/proteccion-identidad-y-datos.tsx" /* webpackChunkName: "component---src-pages-educacion-financiera-proteccion-identidad-y-datos-tsx" */),
  "component---src-pages-estados-financieros-tsx": () => import("./../../../src/pages/estados-financieros.tsx" /* webpackChunkName: "component---src-pages-estados-financieros-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ios-tsx": () => import("./../../../src/pages/ios.tsx" /* webpackChunkName: "component---src-pages-ios-tsx" */),
  "component---src-pages-ss-tsx": () => import("./../../../src/pages/ss.tsx" /* webpackChunkName: "component---src-pages-ss-tsx" */),
  "component---src-pages-tarjetas-tsx": () => import("./../../../src/pages/tarjetas.tsx" /* webpackChunkName: "component---src-pages-tarjetas-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-pages-tortuga-tsx": () => import("./../../../src/pages/tortuga.tsx" /* webpackChunkName: "component---src-pages-tortuga-tsx" */),
  "component---src-pages-une-tsx": () => import("./../../../src/pages/une.tsx" /* webpackChunkName: "component---src-pages-une-tsx" */),
  "component---src-pages-whatsapp-tsx": () => import("./../../../src/pages/whatsapp.tsx" /* webpackChunkName: "component---src-pages-whatsapp-tsx" */),
  "component---src-templates-terms-template-tsx": () => import("./../../../src/templates/termsTemplate.tsx" /* webpackChunkName: "component---src-templates-terms-template-tsx" */)
}

